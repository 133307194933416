import * as React from 'react';
import '../styles/global.css';
import Navbar from './navbar';
import { FaInstagram, FaLinkedin, FaFacebookSquare, FaMapMarkerAlt } from 'react-icons/fa';

const Layout = ({ children }) => {
    return (
        <div className="p-0 m-0">
            <Navbar />
            <main>{children}</main>
            <footer className="w-100 bg-black text-white text-center p-10">
                <div className="grid">
                    <img
                        src="/images/logo_white.svg"
                        alt="rochegrup software logo"
                        width={200}
                        height={80}
                        className="mb-4"
                    />
                    <div className="flex flex-row space-x-6 justify-center sm:justify-start">
                        <ul className="flex flex-col space-y-2 mb-4 items-center sm:items-start">
                            <li>
                                <a href="https://www.rochegrup-softwares.com/">Accueil</a>
                            </li>
                            <li>
                                <a href="https://www.rochegrup-softwares.com/blog">Blog</a>
                            </li>
                            <li>
                                <a href="https://www.rochegrup-softwares.com/fr/mention-legales">Mention légales</a>
                            </li>
                        </ul>
                        <ul className="flex flex-col space-y-2 mb-4 items-center sm:items-start">
                            <li>
                                <a href="https://www.rochegrup-softwares.com/services">Nos services</a>
                            </li>
                            <li>
                                <a href="https://www.rochegrup-softwares.com/expertises">Nos expertises</a>
                            </li>
                            <li>
                                <a href="https://www.rochegrup-softwares.com/fr/recrute">Carrières</a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-row  justify-center items-center mb-4 sm:justify-start">
                        <p>Nous acceptons le bitcoin</p>
                        <img
                            src="/images/bitcoin-icon.svg"
                            alt="bitcoin-logo"
                            width={30}
                            height={30}
                            className="ml-2"
                        />
                    </div>
                    <ul className="flex flex-row space-x-3 mb-4 items-center justify-center sm:justify-start">
                        <li>
                            <a href="https://www.instagram.com/rg.softwares">
                                <FaInstagram className="text-xl" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/rochegrup-softwares/">
                                <FaLinkedin className="text-xl" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/rochegrupsoftwares">
                                <FaFacebookSquare className="text-xl" />
                            </a>
                        </li>
                    </ul>
                    <div className="flex flex-row  justify-center items-center mb-4 sm:justify-start">
                        <FaMapMarkerAlt className="mr-1" />
                        <p className="text-center sm:text-left">Basé en principauté d'Andorre</p>
                    </div>
                    <a className="text-white" href="https://www.rochegrup-softwares.com">
                        © {new Date().getFullYear()} ROCHEGRUP SOFTWARES SLU.
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default Layout;
