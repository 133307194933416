import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { MdLanguage } from 'react-icons/md';
import { useStaticQuery, graphql } from 'gatsby';

import { useI18next } from 'gatsby-plugin-react-i18next';

const Navbar = () => {
    const { i18n, changeLanguage } = useI18next();
    const [open, setOpen] = useState(false);
    const query = graphql`
        {
            allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "menus" }, title: { eq: "home" } } }) {
                nodes {
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        language
                        tags {
                            tag {
                                name
                                link
                            }
                        }
                    }
                }
            }
        }
    `;
    const data = useStaticQuery(query);
    const language = i18n.language === 'en' ? 'English' : 'Français';
    const navInfos = data.allMarkdownRemark.nodes.filter((elem) => {
        if (elem.frontmatter.language === language) return elem;
        return null;
    })[0].frontmatter.tags;

    return (
        <nav className="sticky top-0 flex items-center justify-between flex-wrap bg-black pt-4 pb-4 pr-10 pl-10 shadow-lg">
            <div className="inline-block">
                <img src="/images/logo_white.svg" alt="logo rochegrup softwares" width={200} height={80} />
            </div>
            <div className="block lg:hidden">
                <button
                    className="flex items-center px-3 py-2 border rounded text-white"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <AiOutlineClose /> : <AiOutlineMenu />}
                </button>
            </div>
            <div
                className={`w-full block lg:flex lg:flex-row lg:items-center lg:justify-end lg:w-auto
                ${open ? '' : 'hidden'}`}
            >
                <div className="text-lg lg:flex-grow text-white">
                    {navInfos.map((nav) => {
                        return (
                            <a
                                key={nav.tag.name}
                                href={nav.tag.link}
                                className={
                                    nav.tag.name === 'Contact'
                                        ? 'block mt-4 lg:inline-block lg:mt-0 text-lg lg:px-2 lg:py-2 lg:bg-orange lg:rounded lg:text-white mr-4 lg:uppercase lg:font-semibold'
                                        : 'block mt-4 lg:inline-block lg:mt-0 text-lg hover:text-orange mr-4'
                                }
                            >
                                {nav.tag.name}
                            </a>
                        );
                    })}
                    <div className="relative inline-block">
                        <div>
                            <button
                                type="button"
                                className="inline-flex justify-center items-center w-full text-lg font-medium"
                                id="menu-button"
                                aria-expanded="true"
                                aria-haspopup="true"
                                onClick={() => setOpen(true)}
                            >
                                <MdLanguage className="text-xl mr-1" />
                                {i18n.language}
                            </button>
                        </div>
                        {open && (
                            <div
                                className="origin-top-right absolute right-0 mt-4 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                            >
                                <div className="py-1" role="none">
                                    <span
                                        href="#"
                                        className="text-black hover:text-orange block px-4 py-2 text-sm cursor-pointer"
                                        role="menuitem"
                                        tabIndex="-1"
                                        id="menu-item-0"
                                        onClick={() => {
                                            changeLanguage('en');
                                            setOpen(false);
                                        }}
                                    >
                                        English
                                    </span>
                                    <span
                                        href="#"
                                        className="text-black hover:text-orange block px-4 py-2 text-sm cursor-pointer"
                                        role="menuitem"
                                        tabIndex="-1"
                                        id="menu-item-1"
                                        onClick={() => {
                                            changeLanguage('fr');
                                            setOpen(false);
                                        }}
                                    >
                                        Français
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
